import { WagtailStreamfieldImage } from '@/types';
import Image from '@components/Image';
import Slider from '@components/Slider';
import React from 'react';

type Benefit = {
  icon: WagtailStreamfieldImage;
  name: string;
};

type Props = { benefits: Benefit[] };

function Benefits(props: Props) {
  const { benefits } = props;
  return (
    <div className='streamfield benefits'>
      <Slider>
        {Object.entries(benefits).map(([key, benefit]) => (
          <div className='benefit' key={key}>
            <div className='benefit__icon'>
              <Image image={benefit.icon.image!} alt_text={benefit.icon.alt_text} />
            </div>
            <p className='benefit__text'>{benefit.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Benefits;
