import React, { PropsWithChildren } from 'react';
import AngleRight from '@icons/angle-right.svg';

type Props = {};

function Fallback(props: PropsWithChildren<Props>) {
  const { children } = props;

  return (
    <div className='slider-fallback' aria-busy>
      <div className='slider-fallback__track'>{children}</div>
      <div className='splide__arrows'>
        <button className='splide__arrow splide__arrow--prev'>
          <AngleRight />
        </button>
        <button className='splide__arrow splide__arrow--next'>
          <AngleRight />
        </button>
      </div>
    </div>
  );
}

export default Fallback;
