import React, { PropsWithChildren, Suspense } from 'react';
import Fallback from './Fallback';

const Slider = React.lazy(() => import('./Slider'));

type Props = {};

function SuspendedSlider(props: PropsWithChildren<Props>) {
  return (
    <Suspense fallback={<Fallback {...props} />}>
      <Slider {...props} />
    </Suspense>
  );
}

export default SuspendedSlider;
