import { WagtailImage } from '@/types';
import { Image, Rendition } from '@components/Image';
import clsx from 'clsx';

type Props = {
  title: string;
  productType?: string;
  image?: WagtailImage | null;
};

function Hero(props: Props) {
  const { title, image, productType } = props;

  return (
    <div role='banner' className='hero full-width'>
      {image ? (
        <Image
          image={image}
          quality={90}
          priority
          sizes='100vw'
          spec='fill-1920x500'
          alt_text={image.alt_text}
          className='hero__image full-width'
        >
          <Rendition image={image} mediaQuery='(max-width: 399px)' spec='fill-960x250' />
          <Rendition image={image} mediaQuery='(min-width: 400px)' spec='fill-1920x500' />
        </Image>
      ) : null}
      {productType ? <div className='hero__product-type'>Ihre {productType}</div> : null}
      <h1 className={clsx('hero__title', { 'hero__title--has-type': productType })}>{title}</h1>
    </div>
  );
}

export default Hero;
