import { WagtailStreamfieldImage } from '@/types';
import CustomImage from '@components/Image';
import clsx from 'clsx';

type Props = {
  image: WagtailStreamfieldImage;
  full_width: boolean;
};

function Image(props: Props) {
  const { full_width: fullWidth, image } = props;

  return (
    <div
      className={clsx('streamfield', 'image', {
        'image--full-width': fullWidth,
      })}
    >
      <CustomImage
        image={image.image!}
        className='image__element'
        spec='width-800'
        width={800}
        height={500}
        alt_text={image.alt_text}
        quality={90}
      />
      {
        // <NextImage
        //   className='image__element'
        //   src={addBaseUrl(image.file.src)}
        //   alt={image.alt}
        //   width={image.file.width}
        //   height={image.file.height}
        //   sizes='100vw'
        //   style={
        //     fullWidth
        //       ? {
        //         width: '100%',
        //         height: 'auto',
        //         objectFit: 'cover',
        //       }
        //       : {
        //         height: 'auto',
        //         objectFit: 'contain',
        //         objectPosition: 'center',
        //         margin: 'auto',
        //       }
        //   }
        // />
      }
    </div>
  );
}

export default Image;
