import LoadingSpinner from '@components/LoadingSpinner';
import RawHtml from '@components/RawHtml';
import type { Props as EventFinderProps } from './EventFinder';

import PointIcon from '@icons/point-hand.svg';
import { ChevronDownIcon } from '@radix-ui/react-icons';

type Props = EventFinderProps & { searchParams: Record<string, string> };

function LoadingSkeleton(props: Props) {
  const { title, text, with_selection, products, searchParams } = props;

  const product = products.find(p => p.value.kurs === searchParams?.course);

  return (
    <div className='streamfield event-finder' aria-busy='true' aria-live='polite'>
      <div className='event-finder__icon'>
        <PointIcon />
      </div>
      <h2 className='event-finder__title'>{title}</h2>
      {text ? <RawHtml html={text} className='event-finder__description' /> : null}
      {with_selection ? (
        <div className='select select--rounded'>
          <button className='select__trigger'>
            <span>{product?.value?.title}</span>
            <span className='select__icon'>
              <ChevronDownIcon color='white' width={48} height={48} />
            </span>
          </button>
        </div>
      ) : null}
      <LoadingSpinner />
    </div>
  );
}

export default LoadingSkeleton;
