import { WagtailPage } from '@/types';
import RawHtml from '@components/RawHtml';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type Props = {
  title: string;
  layout: 'layout-1' | 'layout-2';
  text_link: string;
  link_intern: WagtailPage;
  link_extern: string;
  button_text_link: string;
  text_telephone: string;
  telephone: string;
  button_text_telephone: string;
};

function Reservation(props: Props) {
  const {
    title,
    text_link: textLink,
    link_intern: linkIntern,
    link_extern: linkExtern,
    text_telephone: textTelephone,
    button_text_link: buttonTextLink,
    telephone,
    button_text_telephone: buttonTextTelephone,
    layout,
  } = props;

  if (layout === 'layout-2') {
    return (
      <div className='streamfield reservation'>
        <div className='reservation__container'>
          {title ? <h2>{title}</h2> : null}
          <RawHtml html={textTelephone} />
          <a
            href={`tel:${telephone?.replace(/[\s]/g, '')}`}
            className='button reservation__link reservation__link--telephone'
          >
            {buttonTextTelephone}
          </a>
          <RawHtml html={textLink} />
          {linkIntern ? (
            <Link
              href={removeBaseUrl(linkIntern.full_url)}
              passHref
              className='button button--primary reservation__link'
            >
              {buttonTextLink}
            </Link>
          ) : null}
          {linkExtern ? (
            <Link href={linkExtern} passHref className='button button--primary reservation__link'>
              {buttonTextLink}
            </Link>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className='streamfield reservation'>
      <div className='reservation__container'>
        <h2>{title}</h2>
        <RawHtml html={textLink} />
        {linkIntern ? (
          <Link
            href={removeBaseUrl(linkIntern.full_url)}
            passHref
            className='button button--primary reservation__link'
          >
            {buttonTextLink}
          </Link>
        ) : null}
        {linkExtern ? (
          <Link href={linkExtern} passHref className='button button--primary reservation__link'>
            {buttonTextLink}
          </Link>
        ) : null}
        <RawHtml html={textTelephone} />
        <a
          href={`tel:${telephone}`}
          className='button reservation__link reservation__link--telephone'
        >
          {buttonTextTelephone}
        </a>
      </div>
    </div>
  );
}

export default Reservation;
