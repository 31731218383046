import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import Image from '@components/Image';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type Product = {
  product_type: string;
  link_text: string;
  link: WagtailPage;
  image: WagtailStreamfieldImage;
};

type Props = {
  title: string;
  products: Product[];
};

function Products(props: Props) {
  const { title, products } = props;

  return (
    <div className='streamfield products'>
      <h2 className='products__title'>{title}</h2>
      <ul className='products__list'>
        {products.map(product => (
          <li key={product.link_text}>
            <Link href={removeBaseUrl(product.link.full_url)} className='products__link'>
              <div className='products__list-item'>
                {product.image?.image ? (
                  <Image
                    className='products__list-item-image'
                    spec='height-300'
                    image={product.image.image}
                    alt_text={product.image.alt_text}
                    width={300}
                    height={200}
                    quality={90}
                  />
                ) : null}
                <p className='products__list-item-text'>{product.link_text}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Products;
