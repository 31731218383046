import { WagtailStreamfieldImage } from '@/types';
import RawHtml from '@components/RawHtml';
import Slider from '@components/Slider';
import Review from './Review';

export type TReview = {
  name: string;
  text: string;
  avatar: WagtailStreamfieldImage | null;
};

type Props = { reviews: TReview[]; text: string };

function Reviews(props: Props) {
  const { text, reviews } = props;
  return (
    <div className='streamfield reviews'>
      <RawHtml html={text} className='reviews__text' />
      <Slider>
        {Object.entries(reviews).map(([key, review]) => (
          <Review name={review.name} key={key} avatar={review.avatar} text={review.text} />
        ))}
      </Slider>
    </div>
  );
}

export default Reviews;
