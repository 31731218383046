/* eslint-disable react/no-array-index-key */
import { WagtailPage } from '@/types';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

type Button = {
  primary: boolean;
  button_text: string;
  trennlinie: null;
  hinweis: null;
  button_page: WagtailPage | null;
  button_url: string | null;
  anchor: string | null;
  color: 'primary' | 'secondary' | 'outline';
};

type Props = {
  title?: string;
  buttons: Button[];
};

const phoneRegEx = /[\d +]+/;

function isPhoneNumber(phonenumber: string) {
  return phoneRegEx.test(phonenumber);
}

function Buttons(props: Props) {
  const { buttons, title } = props;

  return (
    <div className='streamfield buttons'>
      {title ? <h2 className='buttons__title'>{title}</h2> : null}
      <div className='buttons__list'>
        {buttons.map((button, index) => {
          if (button.anchor) {
            return (
              <Link
                key={`${index}-${button.button_text}`}
                className={clsx('buttons__link', 'button', {
                  'button--primary': button.color === 'primary',
                  'button--secondary': button.color === 'secondary',
                })}
                href={`#${button.anchor}`}
              >
                {button.button_text}
              </Link>
            );
          }

          if (isPhoneNumber(button?.button_url ?? '/')) {
            return (
              <a
                key={`${index}-${button.button_text}`}
                className={clsx('buttons__link', 'button', {
                  'button--primary': button.color === 'primary',
                  'button--secondary': button.color === 'secondary',
                })}
                href={`tel:${button.button_url}`}
              >
                {button.button_text}
              </a>
            );
          }

          return (
            <Link
              href={
                button.button_page?.full_url ??
                (isPhoneNumber(button.button_url ?? '/')
                  ? `tel:${button.button_url}`
                  : (button.button_url ?? '/'))
              }
              key={`${index}-${button.button_text}`}
              className={clsx('buttons__link', 'button', {
                'button--primary': button.color === 'primary',
                'button--secondary': button.color === 'secondary',
              })}
            >
              {button.button_text}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Buttons;
