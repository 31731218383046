import RawHtml from '@components/RawHtml';
import dynamic from 'next/dynamic';
import React from 'react';
import TooltipSvg from 'icons/tooltip.svg';

const Popover = dynamic(() => import('@components/Popover'), {
  loading: () => (
    <div className='course-information__popover popover'>
      <button aria-label='Mehr erfahren' className='course-information__popover popover__trigger'>
        <TooltipSvg width={20} height={20} />
      </button>
    </div>
  ),
});

type CustomKursTypValues = {
  title: string;
  field?: { key: string; value: string };
  text?: string;
  helptext: string | null;
};

type KursTypValues = {
  override?: string;
} & CustomKursTypValues;

type Field =
  | {
      id: string;
      type: 'customkurstypfield';
      value: CustomKursTypValues;
    }
  | { id: string; type: 'kurstypfield'; value: KursTypValues };

type Props = {
  title: string;
  content: Field[];
};

function CourseInformation(props: Props) {
  const { title, content } = props;

  return (
    <div className='streamfield course-information'>
      <h2 className='course-information__title'>{title}</h2>
      <ul className='course-information__list'>
        {content?.map(item => (
          <li className='course-information__list-item' key={item.id}>
            <p className='course-information__list-item-title'>{item.value.title}</p>
            {item.value.text && item.type === 'customkurstypfield' ? (
              <RawHtml html={item.value.text} />
            ) : null}
            {!item.value.text && item.type === 'kurstypfield' ? (
              <RawHtml html={item.value.override} />
            ) : null}
            {item.value.text && item.type === 'kurstypfield' ? (
              <div className='richtext'>
                <p>{item.value.text}</p>
              </div>
            ) : null}
            {item.value.field ? <p>{item.value.field.value}</p> : null}
            {item.value.helptext ? (
              <Popover
                content={item.value.helptext}
                className='course-information__popover'
                ariaButtonText='Mehr erfahren'
                buttonText={<TooltipSvg width={20} height={20} />}
              />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CourseInformation;
