import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import React from 'react';

type Props = { anchor_id: string; content: Streamfield[]; searchParams: any };

function Anchor(props: Props) {
  const { anchor_id, content } = props;

  return (
    <div className='anchor' id={anchor_id}>
      <Streamfields fields={content ?? []} searchParams={props.searchParams} />
    </div>
  );
}

export default Anchor;
