import clsx from 'clsx';
import Image from 'components/Image';
import { TReview } from './Reviews';
import ReviewText from './ReviewText';

function Review(props: TReview) {
  const { text, name, avatar } = props;

  return (
    <div className={clsx('review')}>
      {avatar ? (
        <div className='review__iconwrapper'>
          <Image image={avatar.image!} className='review__icon' alt_text={avatar.alt_text} />
        </div>
      ) : null}
      <ReviewText text={text} />
      <p className='review__name'>{name}</p>
    </div>
  );
}

export default Review;
