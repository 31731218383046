import Accordions from '@streamfields/Accordions';
import Buttons from '@streamfields/Buttons';
import CourseInformation from '@streamfields/CourseInformation';
import CTA from '@streamfields/CTA';
import EyeCatcher from '@streamfields/EyeCatcher';
import Image from '@streamfields/Image';
import KursTypField from '@streamfields/KursTypField';
import Links from '@streamfields/Links';
import Message from '@streamfields/Message';
import Rating from '@streamfields/Rating';
import Reservation from '@streamfields/Reservation';
import Richtext from '@streamfields/Richtext';
import Table from '@streamfields/Table';
import TargetGroup from '@streamfields/TargetGroup';
import TextBlockSnippet from '@streamfields/TextBlockSnippet';
import Anchor from '@streamfields/Anchor';
import Columns from '@streamfields/Columns';
import Contacts from '@streamfields/Contacts';
import Container from '@streamfields/Container';
import CourseHeader from '@streamfields/CourseHeader';
import CourseSummary from '@streamfields/CourseSummary';
import Downloads from '@streamfields/Downloads';
import EventFinder from '@streamfields/EventFinder';
import Media from '@streamfields/Media';
import ProductPresentation from '@streamfields/ProductPresentation';
import Products from '@streamfields/Products';
import ReservationFaq from '@streamfields/ReservationFaq';
import Video from '@streamfields/Video';
import Reviews from './Reviews';
import Benefits from './Benefits';
import ReadMore from './ReadMore';

const streamfields = {
  accordion: Accordions,
  buttons: Buttons,
  code: Richtext,
  courseinformation: CourseInformation,
  cta: CTA,
  eyecatcher: EyeCatcher,
  image: Image,
  abes_field: KursTypField,
  links: Links,
  media: Media,
  message: Message,
  products: Products,
  full_richtext: Richtext,
  rating: Rating,
  reservation: Reservation,
  simple_richtext: Richtext,
  table: Table,
  targetgroup: TargetGroup,
  textblocksnippet: TextBlockSnippet,
  video: Video,
  event_finder: EventFinder,
  event_finder_with_course_selection: EventFinder,
  course_summary: CourseSummary,
  columns: Columns,
  container: Container,
  downloads: Downloads,
  course_header: CourseHeader,
  contacts: Contacts,
  product_presentation: ProductPresentation,
  contact_faq: ReservationFaq,
  anchor: Anchor,
  reviews: Reviews,
  benefits: Benefits,
  read_more: ReadMore,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
