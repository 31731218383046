import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import EyeCatcher from '@streamfields/EyeCatcher';
import Rating from '@streamfields/Rating';
import React from 'react';

type EyecatcherType = {
  icon?: WagtailStreamfieldImage;
  text: string;
  link_intern?: WagtailPage;
  link_extern?: string;
};

type Props = {
  rating: string;
  eyecatcher: EyecatcherType;
};

function CourseHeader(props: Props) {
  const { rating, eyecatcher } = props;
  return (
    <div className='streamfield course-header'>
      <Rating value={rating} />
      <EyeCatcher
        icon={eyecatcher.icon}
        text={eyecatcher.text}
        link_extern={eyecatcher.link_extern}
        link_intern={eyecatcher.link_intern}
      />
    </div>
  );
}

export default CourseHeader;
