/* eslint-disable react/no-array-index-key */
import { WagtailStreamfieldImage } from '@/types';
import Image from '@components/Image';
import RawHtml from '@components/RawHtml';
import React from 'react';

type Contact = {
  text: string;
  image: WagtailStreamfieldImage;
};

type Props = Record<string, Contact>;

function Contacts(props: Props) {
  const contacts = Object.values(props);

  return (
    <div className='streamfield contacts'>
      {contacts.length
        ? contacts.filter(Boolean).map((contact, index) => (
            <div className='contacts__person' key={`${index}-${contact.text}`}>
              {contact?.image?.image ? (
                <Image
                  image={contact.image.image}
                  spec='fill-250x250'
                  className='contacts__image'
                  alt_text={contact.image.alt_text ?? ''}
                />
              ) : null}
              <RawHtml html={contact.text} className='contacts__text' />
            </div>
          ))
        : null}
    </div>
  );
}

export default Contacts;
